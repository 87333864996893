import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Information from '../components/Item/Information'

const Activity = styled.li`
  position: relative;
  margin: 0 0 6em 0;
  width: 100%;
  transition: background 0.2s;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 49%')};
    margin: 0 0 5em 0;
  }
  &:hover {
    background: ${props => props.theme.colors.tertiary};
  }
  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.text};
    text-decoration: none;
    .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 71%;
    }
  }
`

const Title = styled.h2`
  font-size: 1.3em;
  font-weight: 400;
  margin: 1em 1rem 1em 0;
`

const ActCard = ({ slug, thumbnail, title, status, startDate, endDate, ...props }) => {
  return (
    <>
      {thumbnail && (
        <Activity>
          <Link to={`/${status}/${slug}/`}>
            <Img fluid={thumbnail.fluid} backgroundColor={'#eeeeee'} />
            <Title>{title}</Title>
            <Information
              startDate={startDate ? startDate : null}
              endDate={endDate ? endDate : null}
              status={status}
              padding={"0.5em 1em"}
            />
          </Link>
        </Activity>
      )}
    </>
  )
}

export default ActCard
