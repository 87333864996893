import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import Container from '../components/Container'
import SEO from '../components/SEO'
import { startCase } from 'lodash'
import ActCard from "../components/ActCard";
import PageTitle from "../components/PageTitle"
import Slash from '../components/Slash/Slash'
import ItemWrapper from '../components/Item/ItemWrapper'
import Close from '../components/Close'

const Activities = ({ data, pageContext }) => {
  const activities = data.allContentfulActivity.edges
  const { basePath, status } = pageContext
  let ogImage

  try {
    ogImage = activities[0].node.thumbnail.file.url
  } catch (error) {
    ogImage = null
  }

  return (
    <Layout>
      <SEO title={status} image={ogImage} />
      <Container>
        <PageTitle>{status}</PageTitle>
        <Slash />
        <ItemWrapper>
          {(activities.length >= 1) ?
            (
              <CardList>
                {
                  activities.map(({ node: activity }) => (
                    <ActCard key={activity.id} {...activity} basePath={basePath} />
                  ))
                }
              </CardList>
            ) : (
              <Close />
            )}
        </ItemWrapper>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($status: String!) {
    allContentfulActivity(
      filter: {status: {eq: $status}}, sort: {fields: startDate, order: DESC}
    ) {
      edges {
        node {
          slug
          id
          title
          thumbnail {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            file {
              url
            }
          }
          status
          startDate
          endDate
        }
      }
    }
  }
`

export default Activities
